<template>
  <div class="sessionindow">
    <div
      id="messageBox"
      class="mssage-box edit-scroll-style"
      @wheel="handleScroll"
    >
      <div v-if="messages.length == 0" class="no-message">
        <img src="@/assets/AI_icon/icon_ai.png" alt="" />
        <div class="no-message-title">我是AI小文，很高心见到你！</div>
        <div>我可以帮你搜索，答疑，写作，请把你的任务交给我吧～</div>
      </div>
      <template v-else>
        <!-- {{ messages }} -->
        <div
          v-for="(item, index) in messages"
          :key="index"
          class="message-item"
        >
          <div v-if="item.role == 'user'" class="isUser">
            <div>{{ item.content }}</div>
          </div>
          <div v-else class="message-item-box">
            <div v-if="item.content">
              <div class="message-item-header">
                <img src="@/assets/AI_icon/icon_ai.png" alt="" />
                <span
                  >{{ item.contentText ? "思考完成" : "思考中..." }}
                  <template v-if="item.time">(用时{{ item.time }}s)</template>
                </span>
                <i
                  :class="
                    item.showItem ? 'el-icon-arrow-down' : 'el-icon-arrow-up'
                  "
                  @click="showClickItem(item)"
                ></i>
              </div>
              <!-- <VueMarkdown
                v-show="item.showItem"
                class="think message-cnt"
                :source="item.content"
              ></VueMarkdown> -->
              <div
                v-if="item.showItem"
                v-katex:auto
                class="message-cnt think"
                v-html="item.contentView"
              ></div>
            </div>

            <!-- <VueMarkdown
              v-if="item.contentText"
              class="message-cnt"
              :source="item.contentText"
            ></VueMarkdown> -->
            <div
              v-if="item.contentText"
              v-katex:auto
              class="message-cnt"
              v-html="item.contentTextView"
            ></div>
            <div class="btn-cnt-box">
              <div v-if="!isLoading" class="btn-cnt">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="复制"
                  placement="top"
                >
                  <img
                    src="@/assets/AI_icon/icon_copy.png"
                    alt=""
                    @click="copy(item)"
                  />
                </el-tooltip>
              </div>
            </div>
          </div>
        </div>
        <!-- <div @click="abortRequest()">停止问答</div> -->
      </template>
    </div>
    <div class="footer">
      <div class="cnt-box">
        <el-input
          v-model="search.text"
          type="textarea"
          :rows="2"
          placeholder="有问题，尽管问"
          @keyup.enter.native="sendMessage()"
        >
        </el-input>
      </div>
      <div class="btn-box">
        <div class="btn-list-box">
          <div
            class="btn-list"
            :class="{ on: search.isDeepSeek }"
            @click="checkSeek()"
          >
            <img src="@/assets/AI_icon/deep_thinking.png" alt="" />
            <img
              class="img-on"
              src="@/assets/AI_icon/deep_thinking_on.png"
              alt=""
            />
            深度思考(R1)
          </div>
          <!-- <div class="btn-list">
            <img src="@/assets/AI_icon/icon_networking.png" alt="" />
            <img
              class="img-on"
              src="@/assets/AI_icon/icon_networking_on.png"
              alt=""
            />
            联网搜索
          </div> -->
          <div class="btn-list" @click="goLink()">
            <img src="@/assets/AI_icon/icon_networking.png" alt="" />
            <img
              class="img-on"
              src="@/assets/AI_icon/icon_networking_on.png"
              alt=""
            />
            ppt工具
          </div>
        </div>
        <div>
          <div
            class="img-btn"
            :class="{ isLoading: isLoading, isDis: search.text.length == 0 }"
            @click="sendMessage()"
          >
            <div v-if="isLoading"></div>
            <img v-else src="@/assets/AI_icon/icon_send.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import VueMarkdown from "vue-markdown";
import { getStore } from "@/core/util/store";
import { aiDetail } from "@/core/api/recognition/ai.js";
import { renderMarkdown } from "./markdownRenderer";
// import "katex/dist/katex.min.css"; // `katex` 样式文件
export default {
  components: {
    // VueMarkdown,
  },
  props: {
    uuid: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      search: {
        isDeepSeek: 0,
        text: "",
      },
      inputText: "",
      messages: [],
      isLoading: false,
      controller: null, // 用于中止请求
      buffer: "", // 流式数据缓冲区
      isNoAdd: false,
    };
  },
  beforeDestroy() {
    this.abortRequest();
  },
  methods: {
    showClickItem(item) {
      // console.log(item.showItem);
      item.showItem = item.showItem ? false : true;
      // window.MathJax.typesetPromise();
    },
    copy(v) {
      let str = v.contentText;

      this.$store.commit("SET_ALL_COPY", {
        key: str,
      });
    },
    getHistory() {
      this.messages = [];
      aiDetail({ uuid: this.uuid }).then((res) => {
        let arr = res.data.data;
        if (arr && arr.length > 0) {
          arr.map((item) => {
            this.messages.push({
              role: "user",
              content: item.text,
              roleType: "isUser",
              showItem: true,
            });
            let data = {
              role: "assistant",
              content: item.think,
              contentView: this.strFn(item.think),
              contentText: item.content,
              contentTextView: this.strFn(item.content),
              showItem: true,
            };
            this.messages.push(data);
          });
        }
        this.messages = JSON.parse(JSON.stringify(this.messages));
        this.$nextTick(() => {
          // window.MathJax.typesetPromise();
        });
      });
    },
    initNewView() {
      this.messages = [];
    },
    handleScroll(event) {
      // 检查是否是滚轮点击事件（通常通过event.button检查）
      // 注意：此方法可能不直接支持检测滚轮点击，因为滚轮点击不是标准的滚轮滚动事件
      if (event.type === "wheel") {
        // 如果需要监听滚轮点击（即中间键点击），可以考虑结合mousedown和mouseup事件
        this.isNoAdd = true;
      }
    },
    checkSeek() {
      this.search.isDeepSeek = this.search.isDeepSeek == 1 ? 0 : 1;
    },
    goLink() {
      this.$router.push({
        path: "/ai-ppt",
      });
    },
    smoothlyScrollToDivBottom(divId) {
      var div = document.getElementById(divId);
      if (this.isNoAdd) return;
      if (div) {
        div.scrollTo({
          top: div.scrollHeight,
          behavior: "smooth", // 实现平滑滚动
        });
      } else {
        // console.error("未找到id为" + divId + "的div元素");
      }
    },

    // 发送消息
    async sendMessage() {
      if (!this.search.text || this.isLoading) {
        this.abortRequest();
        return;
      }
      this.updataView();
      this.isNoAdd = false;
      this.isLoading = true;
      this.messages.push({
        role: "user",
        content: this.search.text.replaceAll("\n", ""),
        roleType: "isUser",
      });
      this.search.uuid = this.uuid;
      let data = JSON.stringify(this.search);
      this.search.text = "";
      try {
        // 初始化流式请求
        this.controller = new AbortController();
        // https://ark.cn-beijing.volces.com/api/v3/bots/chat/completions
        // http://192.168.20.105:9999/recognition/ai/chat
        // console.log();
        const urlStr = "https://release.wtjy.com";
        // const urlStr ="http://192.168.20.105:9999"
        const url =
          (process.env.NODE_ENV == "development" ? urlStr : "") +
          "/recognition/ai/chat";
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "text/event-stream",
            Authorization: "Bearer " + getStore({ name: "access_token" }),
            "SCHOOL-ID": getStore({ name: "school_id" }),
          },
          body: data,
          signal: this.controller.signal,
        });
        // 检查 HTTP 状态码
        if (!response.ok) {
          throw new Error(`HTTP 错误: ${response.status}`);
          // 处理 503 错误（如重试或提示用户）
        }
        // 处理流式数据
        await this.processStream(response);
      } catch (error) {
        this.isNoAdd = true;
        if (error.name !== "AbortError") {
          this.messages.push({
            role: "assistant",
            contentText: "对话生成失败，请重试",
          });
        }
        this.abortRequest();
      } finally {
        this.isLoading = false;
        this.controller = null;
        this.buffer = "";
      }
    },

    // 处理流式响应
    async processStream(response) {
      const reader = response.body.getReader();
      const decoder = new TextDecoder("utf-8");
      let assistantMessage = {
        role: "assistant",
        content: "",
        contentView: "",
        contentTextView: "",
        contentText: "",
        showItem: true,
      };
      const startTime = new Date().getTime();
      // eslint-disable-next-line no-constant-condition
      while (true) {
        const { done, value } = await reader.read();
        if (done === true) break;
        // 解析数据块
        const chunk = decoder.decode(value);
        this.buffer += chunk;
        // 处理完整事件
        while (this.buffer.includes("\n\n")) {
          const eventEndIndex = this.buffer.indexOf("\n\n");
          const eventData = this.buffer.slice(0, eventEndIndex);
          this.buffer = this.buffer.slice(eventEndIndex + 2);
          // console.log(this.buffer);
          // 解析 SSE 格式
          if (eventData.startsWith("data:")) {
            const content = eventData.replace("data:", "").trim();
            let json = JSON.parse(content);
            if (json.type == "stop") {
              this.isNoAdd = true;
              this.abortRequest();
              return;
            }
            if (!assistantMessage.time && json.type == "text") {
              assistantMessage.time = parseInt(
                (new Date().getTime() - startTime) / 1000
              );
            }

            let contentMsg = json.type == "text" ? json.content : json.content;
            assistantMessage.roleType = json.type;
            if (json.type == "think") {
              assistantMessage.content += contentMsg;
              assistantMessage.contentView = this.strFn(
                assistantMessage.content
              );
            } else {
              assistantMessage.contentText += contentMsg;
              // assistantMessage.contentTextView += assistantMessage.contentText;
              assistantMessage.contentTextView = this.strFn(
                assistantMessage.contentText
              );
              // assistantMessage.contentTextView += this.strFn(contentMsg);
            }

            // 实时更新最后一条消息
            if (this.messages[this.messages.length - 1]?.role === "assistant") {
              this.$set(this.messages, this.messages.length - 1, {
                ...assistantMessage,
              });
            } else {
              this.messages.push({ ...assistantMessage });
            }
            // setTimeout(() => {
            //   window.MathJax.typesetPromise();
            //   this.smoothlyScrollToDivBottom("messageBox");
            // }, 1000);
          }
        }
      }
    },
    updataView() {
      this.updataViewTime = setInterval(() => {
        // window.MathJax.typesetPromise();
        this.smoothlyScrollToDivBottom("messageBox");
      }, 1000);
    },
    strFn(content) {
      // 公式情况 \\
      content = content.replaceAll("\\", "\\\\");
      // content = content.replaceAll("$", "\\\\");
      // console.log(content);
      content = renderMarkdown(content);
      return content;
    },
    // 中止请求
    abortRequest() {
      if (this.controller) {
        this.controller.abort();
      }
      clearInterval(this.updataViewTime);
      this.isLoading = false;
      // window.MathJax.typesetPromise();
    },
  },
};
</script>
<style lang="scss" scoped>
.sessionindow {
  width: 100%;
  .btn-cnt-box {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 18px;
    .btn-cnt {
      background: #ffffff;
      border-radius: 17px;
      border: 1px solid #cecece;
      height: 30px;
      padding: 4px 10px;

      img {
        line-height: 1;
        cursor: pointer;
      }
    }
  }

  .no-message {
    text-align: center;
    margin-top: calc((100vh - 480px) / 2);
    .no-message-title {
      color: #0a1119;
      font-size: 16px;
    }
    .no-message-cnt {
      color: #3e4551;
    }
  }
  .message-item {
    margin-bottom: 18px;
    .message-item-box {
      // display: flex;
      .message-cnt {
        margin-left: 28px;
      }
      .message-item-header {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        i {
          cursor: pointer;
          transition: 0.2s linear;
        }
        img {
          width: 30px;
          margin-right: 4px;
        }
      }
    }
  }
  .isUser {
    display: flex;
    justify-content: flex-end;
    div {
      color: #0a1119;
      padding: 6px 10px;
      background: #e8f2ff;
      border-radius: 10px 2px 10px 10px;
      display: inline-block;
    }
  }
  .think {
    border-left: 1px solid #f0f0f0;
    padding-left: 10px;
    color: #8c9094;
  }
  .img-btn {
    width: 28px;
    height: 28px;
    background: #2474ed;
    text-align: center;
    line-height: 28px;
    border-radius: 50%;
    cursor: pointer;
  }
  .isDis {
    background: #d6dee8;
    cursor: default;
  }
  .isLoading {
    background: #2474ed;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    div {
      width: 12px;
      height: 12px;
      display: inline-block;
      background-color: #ffffff;
      border-radius: 2px;
      transition: 0.2s linear;
    }
  }
  .mssage-box {
    height: calc(100vh - 280px);
    margin-top: 18px;
    scroll-behavior: smooth;
    padding-right: 10px;
    overflow-x: hidden;
  }
  .btn-list-box {
    display: flex;
    align-items: center;
  }
  .btn-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;
    .btn-list {
      line-height: 1;
      padding: 4px 10px;
      display: flex;
      align-items: center;
      border-radius: 17px;
      border: 1px solid #cecece;
      background: #ffffff;
      color: #3e4551;

      cursor: pointer;
      + .btn-list {
        margin-left: 8px;
      }
      img {
        margin-right: 2px;
      }
      .img-on {
        display: none;
      }
    }

    .btn-list.on {
      background: #e8f2ff;
      border: 1px solid #d2e4ff;
      color: #2474ed;
      img {
        display: none;
      }
      .img-on {
        display: block;
      }
    }
  }
  .footer {
    width: calc(100% - 36px);
    height: 112px;
    position: absolute;
    background: #ffffff;
    border-radius: 18px;
    border: 1px solid #2474ed;
    bottom: 18px;
    right: 18px;
    overflow: hidden;
    // position: relative;
    .btn-box {
      position: absolute;
      left: 0;
      bottom: 10px;
      width: 100%;
    }
    ::v-deep .el-textarea__inner {
      border: none;
      resize: none; /* 禁止调整大小 */
    }
    input {
      width: 100%;
      max-height: calc(100% - 42px);
      padding: 4px;
      position: relative;
      outline: none;
    }
  }
}
</style>
