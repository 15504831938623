export const newOther = [
  {
    path: "/newOther/newOther",
    name: "/newOther/newOther",
    redirect: "/newOther/teacher",
    meta: {
      newViews: true,
      tabName: "/newOther/newOther",
    },
    component: () => import("@/views/newOther/index.vue"),
    children: [
      {
        path: "/newOther/student",
        name: "/newOther/student",
        meta: {
          newViews: true,
          tabName: "/newOther/newOther",
          title: "学生管理",
        },
        component: () => import("@/views/newOther/student.vue"),
      },
      {
        path: "/newOther/classNum",
        name: "/newOther/classNum",
        meta: {
          newViews: true,
          tabName: "/newOther/newOther",
          title: "班级管理",
        },
        component: () => import("@/views/newOther/classNum.vue"),
      },
      {
        path: "/newOther/teacher",
        name: "/newOther/teacher",
        meta: {
          newViews: true,
          tabName: "/newOther/newOther",
          title: "教师管理",
        },
        component: () => import("@/views/newOther/teacher.vue"),
      },
      {
        path: "/newOther/statParams",
        name: "/newOther/statParams",
        meta: {
          newViews: true,
          tabName: "/newOther/newOther",
          title: "统计参数",
        },
        component: () => import("@/views/newOther/statParams.vue"),
      },
      {
        path: "/newOther/step",
        name: "/newOther/step",
        meta: {
          newViews: true,
          tabName: "/newOther/newOther",
          title: "默认给分步长",
        },
        component: () => import("@/views/newOther/step.vue"),
      },
      {
        path: "/identify/setting",
        name: "/identify/setting",
        meta: {
          newViews: true,
          tabName: "/newOther/newOther",
          title: "批改痕迹识别",
        },
        component: () => import("@/views/newOther/identify.vue"),
      },
      {
        path: "/newOther/division",
        name: "/newOther/division",
        meta: {
          newViews: true,
          tabName: "/newOther/newOther",
          title: "考务分工",
        },
        component: () => import("@/views/newOther/division.vue"),
      },
      {
        path: "/newOther/card",
        name: "/newOther/card",
        meta: {
          newViews: true,
          tabName: "/newOther/newOther",
          title: "答题卡样式",
        },
        component: () => import("@/views/newOther/card.vue"),
      },
      {
        path: "/newOther/journal",
        name: "/newOther/journal",
        meta: {
          newViews: true,
          tabName: "/newOther/newOther",
          title: "日志管理",
        },
        component: () => import("@/views/newOther/journal.vue"),
      },
      {
        path: "/newOther/publish",
        name: "/newOther/publish",
        meta: {
          newViews: true,
          tabName: "/newOther/newOther",
          title: "成绩发布",
        },
        component: () => import("@/views/newOther/publish.vue"),
      },
      {
        path: "/newOther/SchoolLeadership",
        name: "SchoolLeadership",
        meta: {
          newViews: true,
          tabName: "/newOther/newOther",
        },
        component: () =>
          import("@/views/newOther/authority/SchoolLeadership.vue"),
      },
      {
        path: "/newOther/LeaderOfTheGrade",
        name: "LeaderOfTheGrade",
        meta: {
          newViews: true,
          tabName: "/newOther/newOther",
        },
        component: () =>
          import("@/views/newOther/authority/LeaderOfTheGrade.vue"),
      },
      {
        path: "/newOther/SetSubjuect",
        name: "/newOther/SetSubjuect",
        meta: {
          newViews: true,
          tabName: "/newOther/newOther",
        },
        component: () => import("@/views/newOther/SetSubjuect.vue"),
      },
      {
        path: "/newOther/textbook",
        name: "/newOther/textbook",
        meta: {
          newViews: true,
          tabName: "/newOther/newOther",
        },
        component: () => import("@/views/newOther/textbook.vue"),
      },
      {
        path: "/newOther/deletelist",
        name: "/newOther/deletelist",
        meta: {
          newViews: true,
          tabName: "/newOther/newOther",
        },
        component: () => import("@/views/newOther/deletelist.vue"),
      },
      {
        path: "/newOther/teachClassSetting",
        name: "/newOther/teachClassSetting",
        meta: {
          newViews: true,
          tabName: "/newOther/newOther",
          title: "教学班分析设置",
        },
        component: () => import("@/views/newOther/teachClassSetting.vue"),
      },
      {
        path: "/newOther/convert",
        name: "/newOther/convert",
        meta: {
          newViews: true,
          tabName: "/newOther/newOther",
          title: "个性化功能设置",
        },
        component: () => import("@/views/newOther/convert.vue"),
      },
    ],
  },
];
