export const studentsHomework = [
  // {
  //   path: "/sheet/list",
  //   name: "SheetList",
  //   meta: {
  //     name: "/sheet/list",
  //     newViews: true,
  //   },
  //   component: () => import("@/views/sheet/list.vue"),
  // },
  {
    path: "/homework/list",
    name: "homework",
    meta: {
      name: "/homework/list",
      newViews: true,

      title: "智慧作业",
    },
    component: () => import("@/views/studentsHomework/list/index.vue"),
  },
  {
    path: "/homework/report",
    name: "/homework/report",
    meta: {
      name: "/homework/list",

      newViews: true,
      title: "智慧作业",
    },
    component: () => import("@/views/studentsHomework/report/index.vue"),
  },
  {
    path: "/homework/commentary",
    name: "/homework/commentary",
    meta: {
      name: "/homework/list",
      newViews: true,
      title: "智慧作业",
    },
    component: () => import("@/views/studentsHomework/commentary/index.vue"),
  },
  {
    path: "/homework/recycle",
    name: "/homework/recycle",
    meta: {
      name: "/homework/list",
      newViews: true,
      title: "智慧作业",
    },
    component: () => import("@/views/studentsHomework/recycle/index.vue"),
  },
  {
    path: "/homework/card",
    name: "/homework/card",
    meta: {
      name: "/homework/list",
      newViews: true,

      title: "查看作业卡",
    },
    component: () => import("@/views/studentsHomework/card/index.vue"),
  },
  {
    path: "/homework/paperCropper",
    name: "/homework/paperCropper",
    meta: {
      name: "/res/boutique",
      newViews: true,

      title: "作业卡裁切",
    },
    component: () => import("@/views/studentsHomework/paperCropper/index.vue"),
  },
  {
    path: "/homework/cropperTool",
    name: "/homework/cropperTool",
    meta: {
      name: "/res/boutique",
      newViews: true,
      title: "作业卡裁切",
    },
    component: () => import("@/views/studentsHomework/cropperTool/index.vue"),
  },
  {
    path: "/homework/variation",
    name: "/homework/variation",
    meta: {
      name: "/res/boutique",
      newViews: true,
      title: "匹配同类题",
    },
    component: () => import("@/views/studentsHomework/variation/index.vue"),
  },
  {
    path: "/homework/hwCurAnswer",
    name: "/homework/hwCurAnswer",
    meta: {
      name: "/res/boutique",
      newViews: true,
      title: "设置答案",
    },
    component: () => import("@/views/studentsHomework/hwCurAnswer/index.vue"),
  },
  {
    path: "/homework/knowledge",
    name: "/homework/knowledge",
    meta: {
      name: "/res/boutique",

      title: "标注知识点",
    },
    component: () => import("@/views/studentsHomework/knowledge/index.vue"),
  },
];
