//
import request from "@/core/services/axios";
// 获取聊天列表
export function aiList(obj) {
  return request({
    url: "/recognition/ai/list",
    method: "get",
    params: obj,
  });
}
// 获取聊天详情
export function aiDetail(obj) {
  return request({
    url: "/recognition/ai/detail",
    method: "get",
    params: obj,
  });
}
