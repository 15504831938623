import { getStore } from "@/core/util/store";
import router from "@/router";
//用户登录系统跳转页面问题
export function loginLinkFn() {
  // console.log("11111");
  let userInfo = getStore({ name: "userInfo" });
  let { roleId, teacherRoleType } = userInfo;
  if (!userInfo.isDisplayWorkstand || userInfo.schoolVersion == 1) {
    router.push({
      path: "/exam/exam",
    });
    return;
  }
  if (roleId == 5) {
    if (!teacherRoleType) {
      router.push({
        path: "/mark/mark-list",
      });
      return;
    }
    let roleList = [
      {
        url: "/dashboard/teacher",
        type: 1,
        name: "校领导工作台",
        roleId: 5,
      },
      {
        url: "/dashboard/grade/teacher",
        type: 1,
        name: "年级组长工作台",
        roleId: 4,
      },
      {
        url: "/dashboard/preparation/teacher",
        type: 1,
        name: "备课组长工作台",
        roleId: 3,
      },
      {
        url: "/dashboard/classLeader/teacher",
        type: 1,
        name: "班主任工作台",
        roleId: 2,
      },
      {
        url: "/dashboard/courseTeacher/teacher",
        type: 1,
        name: "任课教师工作台",
        roleId: 1,
      },
    ];
    let roleItem = [];
    roleList.map((item) => {
      if (teacherRoleType.indexOf(item.roleId) != -1) {
        roleItem.push(item);
      }
    });
    // console.log(teacherRoleType);
    router.push({
      path: roleItem[0].url,
    });
  } else {
    router.push({
      path: "/dashboard/admin",
    });
  }
}
