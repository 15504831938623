export const exam = [
  //考试管理    <----- start ----->
  {
    path: "/exam/exam",
    name: "examlist",
    meta: {
      name: "/exam/exam",
      newViews: true,
    },
    component: () => import("@/views/exam/ExamNewList/ExamNewList.vue"),
  },
  {
    path: "/exam/ExamClassReport",
    name: "/exam/ExamClassReport",
    meta: {
      name: "/exam/ExamClassReport",
      newViews: true,
      tabName: "/exam/ExamClassReport",
    },
    component: () => import("@/views/exam/ExamClassReport.vue"),
  },
  {
    path: "/exam/exampaper",
    name: "exampaper",
    meta: {
      name: "/exam/exam",
      newViews: true,
      title: "试题管理",
    },
    component: () => import("@/views/exam/ExamPaper/ExamPaper.vue"),
  },
  {
    path: "/exam/importKnowledgePoint",
    name: "exampaper",
    meta: {
      name: "/exam/exam",
      newViews: true,
      title: "导入知识点",
    },
    component: () => import("@/views/exam/importKnowledgePoint/index.vue"),
  },
  {
    path: "/exam/ExamNewPaperDivide",
    name: "ExamNewPaperDivide",
    meta: {
      name: "/exam/exam",
      title: "裁切试题",
      newViews: true,
    },
    component: () => import("@/views/exam/ExamNewPaperDivide/index.vue"),
  },
  {
    path: "/exam/exampaperdivide",
    name: "exampaperdivide",
    meta: {
      name: "/exam/exam",
    },
    component: () => import("@/views/exam/ExamPaperDivide.vue"),
  },
  {
    path: "/exam/templateSplit",
    name: "TemplateSplit",
    meta: {
      name: "/exam/exam",
      title: "拆分小题",
      newViews: true,
    },
    component: () => import("@/views/exam/template/templateSplit.vue"),
  },

  {
    path: "/exam/exampaperpicdivide",
    name: "exampaperpicdivide",
    meta: {
      name: "/exam/exam",
    },
    component: () => import("@/views/exam/ExamPaperPicDivide.vue"),
  },
  {
    path: "/exam/examNewPaperCropper",
    name: "examNewPaperCropper",
    meta: {
      name: "/exam/exam",
      newViews: true,
      title: "裁切试题",
    },
    component: () => import("@/views/exam/ExamNewPaperCropper.vue"),
  },
  {
    path: "/exam/examlore",
    name: "examlore",
    meta: {
      name: "/exam/exam",
    },
    component: () => import("@/views/exam/ExamLore.vue"),
  },
  {
    path: "/exam/ExamLayeredVariant",
    name: "ExamLayeredVariant",
    meta: {
      name: "/exam/exam",
      title: "试题管理 / 匹配拓展题",
      newViews: true,
    },
    component: () => import("@/views/exam/questionSet/ExamLayeredVariant.vue"),
  },
  // {
  //   path: "/exam/examNewLore",
  //   name: "ExamNewLore",
  //   meta: {
  //     name: "/exam/exam",
  //   },
  //   component: () => import("@/views/exam/ExamNewLore.vue"),
  // },
  {
    path: "/exam/examvariation",
    name: "examvariation",
    meta: {
      name: "/exam/exam",
      title: "试题管理 / 匹配同类题",
      newViews: true,
    },
    component: () => import("@/views/exam/questionSet/ExamVariation.vue"),
  },
  {
    path: "/exam/templateedit",
    name: "templateedit",
    meta: {
      name: "/exam/exam",
    },
    component: () => import("@/views/exam/template/templateEdit.vue"),
  },
  {
    path: "/exam/uniontemplateedit",
    name: "uniontemplateedit",
    meta: {
      name: "/exam/unionExam",
    },
    component: () => import("@/views/exam/template/templateEdit.vue"),
  },
  {
    path: "/exam/examstudent",
    name: "examstudent",
    meta: {
      name: "/exam/exam",
    },
    component: () => import("@/views/exam/ExamStudent.vue"),
  },
  //新考生管理
  {
    path: "/exam/ExamNewStudent",
    name: "ExamNewStudent",
    meta: {
      name: "/exam/exam",
      newViews: true,
      keepAlive: true,
      title: "考生管理",
    },
    component: () => import("@/views/exam/ExamNewStudent/ExamNewStudent.vue"),
  },
  {
    path: "/exam/ExamNewTeacher",
    name: "ExamNewTeacher",
    meta: {
      name: "/exam/exam",
      newViews: true,
      keepAlive: true,
      title: "阅卷教师管理",
    },
    component: () => import("@/views/exam/ExamNewTeacher.vue"),
  },
  {
    path: "/exam/examresult",
    name: "examresult",
    meta: {
      name: "/exam/exam",
      newViews: true,
      title: "成绩管理",
    },
    component: () => import("@/views/exam/ExamResult.vue"),
  },
  {
    path: "/exam/ExamNewResult",
    name: "ExamNewResult",
    meta: {
      name: "/exam/exam",
      newViews: true,
      title: "成绩管理",
    },
    component: () => import("@/views/exam/ExamNewResult.vue"),
  },

  {
    path: "/exam/ExamRanking",
    name: "ExamRanking",
    meta: {
      name: "/exam/exam",
      newViews: true,
      title: "临时榜",
    },
    component: () => import("@/views/exam/ExamRanking.vue"),
  },

  {
    path: "/exam/ExamDistribute",
    name: "ExamDistribute",
    meta: {
      name: "/exam/exam",
      newViews: true,
      title: "考务分工",
    },
    component: () => import("@/views/exam/ExamDistribute.vue"),
  },
  {
    path: "/exam/ExamPaperAnswer",
    name: "ExamPaperAnswer",
    meta: {
      name: "/exam/exam",
      title: "答案设置",
      newViews: true,
    },
    component: () => import("@/views/exam/ExamPaperAnswer/index.vue"),
  },

  {
    path: "/exam/ExamStudentScore",
    name: "ExamStudentScore",
    meta: {
      name: "/exam/exam",
      newViews: true,
      title: "调整成绩",
    },
    component: () => import("@/views/exam/ExamStudentScore.vue"),
  },
  {
    path: "/exam/ExamCorrectTask",
    name: "ExamCorrectTask",
    meta: {
      name: "/exam/exam",
      newViews: true,
      title: "阅卷分配",
    },
    component: () => import("@/views/exam/ExamCorrectTask/ExamCorrectTask.vue"),
  },
  {
    path: "/exam/ExamSchoolReadCorrectTask",
    name: "ExamSchoolReadCorrectTask",
    meta: {
      name: "/exam/exam",
      newViews: true,
    },
    component: () => import("@/views/exam/ExamSchoolReadCorrectTask.vue"),
  },
  {
    path: "/exam/ExamCutAnswer",
    name: "ExamCutAnswer",
    meta: {
      name: "/exam/exam",
      newViews: true,
      title: "试题管理 / 设置答案",
    },
    component: () => import("@/views/exam/ExamCutAnswer/index.vue"),
  },
  {
    path: "/exam/ExamTaskRules",
    name: "ExamTaskRules",
    meta: {
      name: "/exam/exam",
      newViews: true,
      title: "阅卷分配 / 设置分项给分",
    },
    component: () => import("@/views/exam/ExamTaskRules/ExamTaskRules.vue"),
  },
  {
    path: "/exam/ExamQuestionArea",
    name: "ExamQuestionArea",
    meta: {
      name: "/exam/exam",
      newViews: true,
      title: "阅卷分配 / 设置分项给分 / 设置打分区域",
    },
    component: () => import("@/views/exam/ExamQuestionArea.vue"),
  },
  {
    path: "/exam/ExamTaskTeacherList",
    name: "ExamTaskTeacherList",
    meta: {
      name: "/exam/exam",
      newViews: true,
    },
    component: () => import("@/views/exam/ExamTaskTeacherList.vue"),
  },
  {
    path: "/exam/ExamTaskSchoolGroup",
    name: "ExamTaskSchoolGroup",
    meta: {
      name: "/exam/exam",
      newViews: true,
    },
    component: () => import("@/views/exam/ExamTaskSchoolGroup.vue"),
  },

  {
    path: "/exam/ExamSetTaskRules",
    name: "ExamSetTaskRules",
    meta: {
      name: "/exam/exam",
      newViews: true,
      title: "阅卷分工 / 批量设置规则",
    },
    component: () =>
      import("@/views/examUnion/ExamSetTaskRules/ExamSetTaskRules.vue"),
  },
  {
    path: "/exam/ExamClassReadCorrectTask",
    name: "ExamClassReadCorrectTask",
    meta: {
      name: "/exam/exam",
      newViews: true,
      title: "班级批阅",
    },
    component: () => import("@/views/exam/ExamClassReadCorrectTask.vue"),
  },
  {
    path: "/exam/unionExamClassReadCorrectTask",
    name: "unionExamClassReadCorrectTask",
    meta: {
      name: "/exam/unionExam",
      newViews: true,
    },
    component: () => import("@/views/exam/ExamClassReadCorrectTask.vue"),
  },
  {
    path: "/exam/ExamChooseQuestion",
    name: "ExamChooseQuestion",
    meta: {
      name: "/exam/exam",
      newViews: true,
    },
    component: () => import("@/views/exam/ExamChooseQuestion.vue"),
  },
  {
    path: "/exam/ExamBindQuestion",
    name: "ExamBindQuestion",
    meta: {
      name: "/exam/exam",
      newViews: true,
    },
    component: () => import("@/views/exam/ExamBindQuestion.vue"),
  },
  {
    path: "/exam/ExamBindSetQuestion",
    name: "ExamBindSetQuestion",
    meta: {
      name: "/exam/exam",
      newViews: true,
    },
    component: () => import("@/views/exam/ExamBindSetQuestion.vue"),
  },
  {
    path: "/exam/scanSheet",
    name: "scanSheet",
    meta: {
      name: "/exam/exam",
      newViews: true,
      title: "扫描管理",
    },
    component: () => import("@/views/exam/scanSheet/scanSheet.vue"),
  },

  {
    path: "/exam/ExamTemplate",
    name: "ExamTemplate",
    meta: {
      name: "/exam/exam",
      title: "模版设置",
      newViews: true,
    },
    component: () => import("@/views/exam/ExamTemplate/ExamTemplate.vue"),
  },
  {
    path: "/exam/ExamMatchTheCandidate",
    name: "ExamMatchTheCandidate",
    meta: {
      name: "/exam/exam",
      newViews: true,
      title: "匹配考生",
    },
    component: () => import("@/views/exam/ExamMatchTheCandidate.vue"),
  },
  {
    path: "/exam/twoWayCheckList",
    name: "twoWayCheckList",
    meta: {
      name: "/exam/exam",
      newViews: true,
      keepAlive: true,
      title: "双向细目表",
    },
    component: () => import("@/views/exam/twoWayCheckList/index.vue"),
  },
  {
    path: "/exam/paperMonitor",
    name: "paperMonitor",
    meta: {
      name: "/exam/exam",
      newViews: true,
      keepAlive: true,
    },
    component: () => import("@/views/exam/paperMonitor/index.vue"),
    children: [
      {
        path: "/exam/MarkingProgress",
        name: "MarkingProgress",
        meta: {
          name: "/exam/exam",
          newViews: true,
        },
        component: () =>
          import("@/views/exam/paperMonitor/MarkingProgress.vue"),
      },
      {
        path: "/exam/IsReading",
        name: "IsReading",
        meta: {
          name: "/exam/exam",
          newViews: true,
        },
        component: () => import("@/views/exam/paperMonitor/IsReading.vue"),
      },
      {
        path: "/exam/schoolMarkingProgress",
        name: "SchoolMarkingProgress",
        meta: {
          name: "/exam/exam",
          newViews: true,
        },
        component: () =>
          import("@/views/exam/paperMonitor/schoolMarkingProgress.vue"),
      },
      {
        path: "/exam/GradeQuality",
        name: "GradeQuality",
        meta: {
          name: "/exam/exam",
          newViews: true,
          keepAlive: true,
        },
        component: () => import("@/views/exam/paperMonitor/GradeQuality.vue"),
      },
      {
        path: "/exam/SamplePaperInspection",
        name: "SamplePaperInspection",
        meta: {
          name: "/exam/exam",
          newViews: true,
        },
        component: () =>
          import("@/views/exam/paperMonitor/SamplePaperInspection.vue"),
      },

      {
        path: "/exam/GradingCurve",
        name: "GradingCurve",
        meta: {
          name: "/exam/exam",
          newViews: true,
        },
        component: () => import("@/views/exam/paperMonitor/GradingCurve.vue"),
      },
      {
        path: "/exam/MarkingTheAlarm",
        name: "MarkingTheAlarm",
        meta: {
          name: "/exam/exam",
          newViews: true,
        },
        component: () =>
          import("@/views/exam/paperMonitor/MarkingTheAlarm.vue"),
      },

      {
        path: "/exam/MultipleMonitoring",
        name: "MultipleMonitoring",
        meta: {
          name: "/exam/exam",
          newViews: true,
        },
        component: () =>
          import("@/views/exam/paperMonitor/MultipleMonitoring.vue"),
      },

      {
        path: "/exam/MarkingReappraisal",
        name: "MarkingReappraisal",
        meta: {
          name: "/exam/exam",
          newViews: true,
        },
        component: () =>
          import("@/views/exam/paperMonitor/MarkingReappraisal.vue"),
      },

      {
        path: "/exam/MarkStudent",
        name: "MarkStudent",
        meta: {
          name: "/exam/exam",
          newViews: true,
        },
        component: () => import("@/views/exam/paperMonitor/MarkStudent.vue"),
      },

      {
        path: "/exam/ArbitrationMonitoring",
        name: "ArbitrationMonitoring",
        meta: {
          name: "/exam/exam",
          newViews: true,
          keepAlive: true,
        },
        component: () =>
          import("@/views/exam/paperMonitor/ArbitrationMonitoring.vue"),
      },
    ],
  },
];
