import MarkdownIt from "markdown-it";

export function renderMarkdown(content) {
  const md = new MarkdownIt({
    html: false,
    breaks: true,
    linkify: true,
  });

  // md.use(require("markdown-it-katex"), {
  //   delimiters: [
  //     { left: "$$", right: "$$", display: true },
  //     { left: "$", right: "$", display: false },
  //   ],
  // });

  // 使用markdown-it-katex插件来支持数学公式
  // md.use(require("markdown-it-katex"));

  // 将Markdown内容转换为HTML
  return md.render(content);
}
