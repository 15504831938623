var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home",class:{
    'home-full-screen': _vm.$route.meta.fullScreen,
    iframe: _vm.$route.meta.iframe,
  }},[(!_vm.$route.meta.fullScreen)?_c('JZJXHeader',{staticClass:"new-view",on:{"newList":_vm.newList,"isExpired":_vm.isExpiredChange}}):_vm._e(),_c('section',{key:_vm.newKeys,ref:"ktContent",class:[
      { isPicDivide: _vm.getIsPicDivide, isUserInfo: _vm.getIsUserInfo },
      _vm.isExpired ? 'cnt-box-notallow' : 'cnt-box',
    ],attrs:{"id":"kt_content"}},[_c('transition',{attrs:{"name":"fade-transform"}},[_c('keep-alive',{attrs:{"include":_vm.allKeepAlive}},[(_vm.$route.meta.keepAlive)?_c('router-view'):_vm._e()],1)],1),_c('transition',{attrs:{"name":"fade-transform"}},[(!_vm.$route.meta.keepAlive)?_c('router-view'):_vm._e()],1)],1),(!_vm.$route.meta.fullScreen && _vm.$route.name != 'xuekewang')?_c('JZJXFooter'):_vm._e(),_c('KTScrollTop'),_c('modelTips'),_c('sseDrawer')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }