<template>
  <div class="sseDrawer">
    <div class="btn-fotRight" @click="init()">
      <div class="img-box">
        <img src="@/assets/AI_icon/AI.gif" alt="" />
      </div>

      <span>AI小文</span>
    </div>

    <el-drawer :visible.sync="drawer" :before-close="handleClose" size="700px">
      <div slot="title">
        <div>
          <img src="@/assets/AI_icon/icon_title.png" alt="" />
          <span class="drawer-title">AI小文</span>
        </div>
      </div>
      <div class="icon-title">
        <div class="icon-title-list" :class="{ active: showType === 2 }">
          <el-tooltip
            class="item"
            effect="dark"
            content="提问记录"
            placement="top"
          >
            <img
              v-if="showType == 1"
              src="@/assets/AI_icon/icon_his.png"
              alt=""
              @click="checkShowType()"
            />
            <img
              v-if="showType == 2"
              src="@/assets/AI_icon/icon_his_on.png"
              alt=""
              @click="checkShowType()"
            />
          </el-tooltip>
        </div>
        <div class="icon-title-list">
          <el-tooltip
            class="item"
            effect="dark"
            content="新增提问"
            placement="top"
          >
            <img
              src="@/assets/AI_icon/icon_conversation.png"
              alt=""
              @click="getNewUuid()"
            />
          </el-tooltip>
        </div>
      </div>
      <sessionindow
        v-show="showType == 1"
        ref="sessionindow"
        :uuid="uuid"
      ></sessionindow>
      <historicalRecord
        v-show="showType == 2"
        ref="historicalRecord"
        @checkItem="checkItem"
      ></historicalRecord>
    </el-drawer>
  </div>
</template>
<script>
import sessionindow from "./sessionindow";
import historicalRecord from "./historicalRecord";
import { setStore, getStore } from "@/core/util/store";
export default {
  name: "SseDrawer",
  components: {
    historicalRecord,
    sessionindow,
  },
  data() {
    return {
      drawer: false,
      str: "",
      uuid: "",
      showType: 1,
      options: {
        sanitize: false,
        escapes: "",
      },
    };
  },
  created() {},
  methods: {
    checkItem(uuid) {
      this.uuid = uuid;
      this.showType = 1;
      setStore({ name: "ai_uuid", content: uuid });
      this.$nextTick(() => {
        this.$refs.sessionindow.getHistory();
      });
    },
    checkShowType() {
      this.showType = this.showType == 1 ? 2 : 1;
      if (this.showType == 2) {
        this.$refs.historicalRecord.init();
        this.$refs.sessionindow.abortRequest();
      }
    },
    lockScroll() {
      // 锁定滚动
      document.body.classList.add("no-scroll");
      document.body.style.width = "calc(100vw - 17px)";
    },
    unlockScroll() {
      document.body.style.width = "100%";
      // 解锁滚动
      document.body.classList.remove("no-scroll");
    },
    handleClose(done) {
      // this.$confirm("确认关闭？", "提示")
      //   .then(() => {
      done();
      this.closeFn();
      // })
      // .catch(() => {});
    },
    closeFn() {
      this.unlockScroll();
    },
    strFn(content) {
      // 公式情况
      return content.replaceAll("\\", "\\\\");
    },
    generateUUID() {
      const buffer = new Uint8Array(16);
      crypto.getRandomValues(buffer);

      // 设置 UUID 版本和变体
      buffer[6] = (buffer[6] & 0x0f) | 0x40; // Version 4
      buffer[8] = (buffer[8] & 0x3f) | 0x80; // Variant RFC 4122

      const hex = Array.from(buffer)
        .map((b) => b.toString(16).padStart(2, "0"))
        .join("");

      return `${hex.slice(0, 8)}-${hex.slice(8, 12)}-${hex.slice(
        12,
        16
      )}-${hex.slice(16, 20)}-${hex.slice(20)}`;
    },
    getNewUuid() {
      this.showType = 1;
      if (
        !this.$refs.sessionindow.messages ||
        this.$refs.sessionindow.messages.length == 0
      ) {
        return;
      }
      this.uuid = this.generateUUID();
      setStore({ name: "ai_uuid", content: this.uuid });

      this.$nextTick(() => {
        this.$refs.sessionindow.initNewView();
        this.$refs.sessionindow.abortRequest();
      });
    },
    setUUid() {
      // 先判断浏览器是不是已经存在uuid了
      const uuid = getStore({ name: "ai_uuid" });
      if (uuid) {
        this.uuid = uuid;
      } else {
        this.uuid = this.generateUUID();
        setStore({ name: "ai_uuid", content: this.uuid });
      }
    },
    init() {
      this.setUUid();
      this.drawer = true;
      this.$nextTick(() => {
        this.lockScroll();
        this.$refs.sessionindow.getHistory();
        this.$refs.sessionindow.messages = [];
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.sseDrawer {
  ::v-deep .el-drawer__header {
    border-bottom: 1px solid #ededef;
    padding: 8px 18px;
    margin-bottom: 0;
    .drawer-title {
      color: #051c31;
      font-size: 16px;
    }
  }
  ::v-deep .el-drawer__body {
    padding: 18px;
    .icon-title {
      display: flex;
      justify-content: space-between;
    }
    .icon-title-list {
      background: #f3f4f7;
      border-radius: 8px;
      width: 30px;
      height: 30px;
      text-align: center;
      line-height: 30px;
      cursor: pointer;
      user-select: none;
      img {
        line-height: 1;
        width: 18px;
        vertical-align: middle;
      }
    }
    .active {
      background: #2474ed;
    }
  }
  .icon-title {
    display: flex;
  }
  .btn-fotRight {
    position: fixed;
    right: 26px;
    bottom: 100px;
    z-index: 1000;
    width: 60px;
    text-align: center;
    cursor: pointer;
    user-select: none;
    ruby-span: {
      color: #3e4551;
      font-size: 12px;
    }
    div {
      width: 60px;
      height: 60px;
      background: linear-gradient(132deg, #c8d8ff 0%, #ffd0e4 100%);
      box-shadow: 0px 4px 5px 0px rgba(129, 155, 255, 0.23);
      border-radius: 50%;
      border: 2px solid #ffffff;
      img {
        width: 100%;
      }
    }
  }
}
</style>
