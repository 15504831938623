<template>
  <div class="historicalRecord">
    <template v-if="nowList.length > 0">
      <div style="margin-top: 12px">今天</div>
      <div
        v-for="(item, index) in nowList"
        :key="index"
        class="historicalRecord-item"
        @click="checkItem(item)"
      >
        <div>{{ item.name }}</div>
        <div class="time">{{ item.timeStr }}</div>
      </div>
    </template>
    <template v-if="historyList.length > 0">
      <div style="margin-top: 12px">历史提问</div>
      <div
        v-for="(item, index) in historyList"
        :key="index + 'historyList'"
        class="historicalRecord-item"
        @click="checkItem(item)"
      >
        <div>{{ item.name }}</div>
        <div class="time">{{ item.timeStr }}</div>
      </div>
    </template>
  </div>
</template>
<script>
import { aiList } from "@/core/api/recognition/ai.js";
import moment from "moment";
export default {
  name: "HistoricalRecord",
  data() {
    return {
      historyList: [],
      nowList: [],
    };
  },
  created() {},
  methods: {
    checkItem(item) {
      this.$emit("checkItem", item.uuid);
    },
    init() {
      aiList().then((res) => {
        let today = moment().format("YYYY-MM-DD");
        let nowList = res.data.data.filter((item) => {
          let time = item.createTime.split(" ");
          if (time[0] == today) {
            return true;
          } else {
            return false;
          }
        });
        this.nowList = nowList.map((item) => {
          let time = item.createTime.split(" ");
          let timeArr = time[1].split(":");
          item.timeStr = timeArr[0] + ":" + timeArr[1];
          return item;
        });
        let historyList = res.data.data.filter((item) => {
          let time = item.createTime.split(" ");
          if (time[0] != today) {
            return true;
          } else {
            return false;
          }
        });
        this.historyList = historyList.map((item) => {
          let time = item.createTime.split(" ");
          item.timeStr = time[0];
          return item;
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.historicalRecord {
  .historicalRecord-item {
    background: #f5f6f9;
    border-radius: 10px;
    padding: 10px;
    color: #0a1119;
    margin-top: 12px;
    cursor: pointer;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .time {
      width: 90px;
      flex-shrink: 0;
      text-align: right;
    }
  }
}
</style>
